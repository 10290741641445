import { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import axios from "axios";


function FeedbackSuccess() {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const id = queryParams.get('id');
    const pid = queryParams.get('pid');
    const sec = queryParams.get('sec');
    const [authenticated, setAuthenticated] = useState(false);

    useEffect(() => {
        const getFormOptions = async () => {
            try {
                await axios.get(`/api/feedback?id=${id}&pid=${pid}&sec=${sec}`);
                setAuthenticated(true)
            } catch (error) {
                window.location.href = "/feedback/invalid";
            }
        };
    
        getFormOptions();
    }, [id, pid, sec]);

    if(!authenticated) {
        return(
            <>
           
                <div className='card-body'>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh"
                    }}>
                        <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </>
        ); 
    }

    return(
    <div className='feedback-total'>
        <p style={{textAlign: 'center'}} className='img-feedback'><img src="/dist/img/logoAN-feedback-white.png" alt="logo"/></p>
        <div className='feedback-message'>
            <div id="info-div" style={{textAlign: 'center'}}>
                <h2>Seu feedback foi enviado com sucesso!</h2>
                <p>Muito obrigado por nos dar sua avaliação, adoramos sua participação</p>
            </div>

        </div>
    </div>
    );
}

export default FeedbackSuccess;
