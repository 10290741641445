import FeedbackModal from './FeedbackModal';
import { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import axios from "axios";

function FeedbackPage() {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const id = queryParams.get('id');
    const pid = queryParams.get('pid');
    const sec = queryParams.get('sec');
    const [authenticated, setAuthenticated] = useState(false);

    useEffect(() => {
        const getFormOptions = async () => {
            try {
                await axios.post(`/api/negative/feedback?id=${id}&pid=${pid}&sec=${sec}`);
                setAuthenticated(true)
            } catch (error) {
                window.location.href = "/feedback/invalid";
            }
        };
    
        getFormOptions();
    }, [id, pid, sec]);

    if(!authenticated) {
        return(
            <>
           
                <div className='card-body'>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh"
                    }}>
                        <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </>
        ); 
    }

    return(
    <div className='feedback-container'>
        <FeedbackModal/>
    </div>
    );
}

export default FeedbackPage;
