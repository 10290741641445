// AnalysisHome.jsx

import React, { useState } from "react";
import AppHeader from "../../basic/app-header/AppHeader";
import AppMenu from "../../basic/app-menu/AppMenu";
import AppFooter from "../../basic/app-footer/AppFooter";
import AppSetting from "../../basic/app-setting/AppSetting";
import AnalysisDescription from "./AnalysisDescription"; // Componente personalizado
import {
  Button,
  Container,
  Typography,
  Box,
  Grid,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Divider,
  useTheme,
  Alert,
  Paper,
} from "@mui/material";
import ArticleIcon from "@mui/icons-material/Article";
import GavelIcon from "@mui/icons-material/Gavel";
import DescriptionIcon from "@mui/icons-material/Description";

export default function AnalysisHome() {
  const theme = useTheme();
  const [selectedOption, setSelectedOption] = useState(null);
  const [error, setError] = useState(false);

  const content = {
    Contrato: {
      title: "Análise de Contrato",
      description: `
A funcionalidade de Análise de Contrato foi desenvolvida para agilizar o processo de revisão de documentos jurídicos, proporcionando aos advogados uma ferramenta eficiente para iniciar seu trabalho. Abaixo estão os detalhes sobre o uso e funcionamento desta funcionalidade:

## Como Funciona
- **Envio de Contrato:** O advogado pode enviar um contrato diretamente pelo sistema.
- **Formulário de Detalhamento:** Junto ao envio do contrato, um formulário deve ser preenchido com as seguintes informações:
  - **Tipo de Contratante e Contratado:** Identifique as partes envolvidas no contrato.
  - **Natureza do Contrato:** Selecione se o contrato é de natureza empresarial ou trabalhista.
  - **Foco da Análise:** Especifique o principal objetivo da análise.
  - **Descrição da Análise:** Forneça uma breve descrição, entitulando sua análise.

## Processamento por IA
O contrato, juntamente com as informações fornecidas no formulário, é enviado para nosso sistema. Utilizando uma IA, o sistema aplica prompts pré-configurados para realizar uma análise inicial do documento.

## Resultados da Análise
A análise inicial visa poupar tempo e fornecer uma base para o trabalho do advogado. No entanto, é importante observar que:
- **Conferência Necessária:** As informações geradas pela IA podem não ser 100% precisas.
- **Verificação dos Dados:** É imprescindível que os advogados verifiquem e validem todas as informações antes de procederem com suas conclusões e ações jurídicas.

Esta ferramenta foi projetada para otimizar seu tempo e auxiliar no início de suas análises contratuais, mas a responsabilidade final pela precisão e validade das informações permanece com o profissional jurídico.`,
      route: "contract",
      icon: <ArticleIcon fontSize="large"  sx={{color: "#6c757d"}} />,
    },
    Sentença: {
      title: "Análise de Sentença",
      description: `
A funcionalidade de Análise de Sentença foi criada para acelerar o processo de revisão de sentenças jurídicas, proporcionando aos advogados uma ferramenta eficiente e precisa para iniciar seu trabalho. Abaixo estão os detalhes sobre o uso e funcionamento desta funcionalidade:

## Passos Para Utilização
1. **Envio da Sentença:** O advogado pode enviar uma sentença diretamente pelo sistema.
2. **Formulário de Detalhamento:** Junto ao envio da sentença, um formulário deve ser preenchido com as seguintes informações:
   - **Partes Envolvidas:** Identifique se as partes são autor ou réu.
   - **Natureza da Sentença:** Selecione se a sentença é de natureza empresarial ou trabalhista.
   - **Descrição da Análise:** Forneça uma breve descrição, entitulando sua análise.

## Processamento por IA
A sentença, juntamente com as informações fornecidas no formulário, é enviada para nosso sistema. Utilizando uma IA, o sistema aplica prompts pré-configurados para realizar uma análise inicial do documento.

## Resultados da Análise
A análise inicial visa poupar tempo e fornecer uma base para o trabalho do advogado. No entanto, é importante observar que:
- **Conferência Necessária:** As informações geradas pela IA podem não ser 100% precisas.
- **Verificação dos Dados:** É imprescindível que os advogados verifiquem e validem todas as informações antes de procederem com suas conclusões e ações jurídicas.

Esta ferramenta foi projetada para otimizar seu tempo e auxiliar no início de suas análises de sentenças, mas a responsabilidade final pela precisão e validade das informações permanece com o profissional jurídico.`,
      route: "sentence",
      icon: <GavelIcon fontSize="large" sx={{color: "#6c757d"}} />,
    },
    Prognóstico: {
      title: "Gerar Carta de Prognóstico",
      description: `
Esta funcionalidade utiliza IA para gerar cartas de prognóstico baseadas em arquivos iniciais de processos jurídicos. Através do envio de um arquivo inicial do processo, a IA analisa as informações e produz uma carta de prognóstico detalhada, auxiliando os advogados no planejamento de estratégias jurídicas. Abaixo estão os detalhes sobre o uso e funcionamento desta funcionalidade:

## Principais Recursos
- **Envio do Arquivo Inicial do Processo:** O advogado pode enviar o arquivo inicial do processo diretamente pelo sistema.
- **Processamento por IA:** O arquivo enviado é processado por nossa IA, que analisa os dados e gera uma carta de prognóstico personalizada.
- **Resultados da Carta de Prognóstico:** A carta gerada oferece insights e sugestões baseadas na análise da IA, proporcionando uma base sólida para o desenvolvimento de estratégias jurídicas.
- **Verificação e Ajustes:** É importante que os advogados revisem e ajustem as informações fornecidas pela IA para garantir a precisão e relevância da carta de prognóstico.

## Responsabilidade e Ajustes
Embora a IA auxilie no início da análise, a responsabilidade final pela precisão e aplicabilidade das informações permanece com o profissional jurídico.`,
      route: "prognostic",
      icon: <DescriptionIcon fontSize="large" sx={{color: "#6c757d"}} />,
    },
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setError(false); // Resetar erro ao selecionar uma opção
  };

  const handleNextClick = () => {
    if (selectedOption) {
      window.location.href = `/request/analysis/${content[selectedOption].route}`;
    } else {
      setError(true);
    }
  };

  return (
    <>
      <AppHeader />
      <AppMenu />

      <div className="content-wrapper">
        {/* Cabeçalho da Página */}
        <section className="content-header" style={{ marginBottom: -5 }}>
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <Typography variant="h4">AVIA</Typography>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/">Início</a>
                  </li>
                  <li className="breadcrumb-item active">AVIA</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        {/* Conteúdo Principal */}
        <section className="content">
          <Container maxWidth="xl">
            <Box mt={4}>
              <Grid container spacing={4}>
                {/* Coluna Esquerda: Cards de Serviços */}
                <Grid item xs={12} md={4}>
                  <Typography variant="h5" gutterBottom>
                    Serviços de IA (AVIA)
                  </Typography>
                  <Typography variant="body1" color="textSecondary" paragraph>
                    Selecione uma das opções abaixo para realizar a análise desejada.
                  </Typography>

                  <Grid container spacing={2}>
                    {Object.keys(content).map((optionKey) => {
                      const { title, icon } = content[optionKey];
                      return (
                        <Grid item xs={12} key={optionKey}>
                          <Card
                            sx={{
                              border:
                                selectedOption === optionKey
                                  ? `2px solid ${theme.palette.primary.main}`
                                  : "none",
                              transition: "transform 0.2s ease, box-shadow 0.2s ease",
                              "&:hover": {
                                transform: "translateY(-4px)",
                                boxShadow: theme.shadows[4],
                              },
                            }}
                          >
                            <CardActionArea onClick={() => handleOptionClick(optionKey)}>
                              <CardMedia
                                component="div"
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: 100,
                                  backgroundColor: theme.palette.grey[100],
                                }}
                              >
                                {icon}
                              </CardMedia>
                              <Divider />
                              <CardContent>
                                <Typography variant="h6" align="center" gutterBottom>
                                  {title}
                                </Typography>
                              </CardContent>
                            </CardActionArea>
                          </Card>
                        </Grid>
                      );
                    })}
                  </Grid>

                  {/* Botão "Próximo" */}
                  <Box mt={4} textAlign="center">
                    <Button
                      variant="contained"
                      sx={{backgroundColor: "#6c757d"}}
                      size="large"
                      onClick={handleNextClick}
                      fullWidth
                    >
                      Próximo
                    </Button>
                  </Box>
                </Grid>

                {/* Coluna Direita: Descrição do Serviço Selecionado */}
                <Grid item xs={12} md={8}>
                  {selectedOption ? (
                    <Paper
                      elevation={3}
                      sx={{
                        padding: 3,
                        maxHeight: 730, // Define a altura máxima
                        overflowY: "auto", // Permite rolagem vertical
                      }}
                    >
                      <Typography variant="h5" gutterBottom>
                        {content[selectedOption].title}
                      </Typography>
                      <AnalysisDescription description={content[selectedOption].description} />
                    </Paper>
                  ) : (
                    <Paper
                      elevation={1}
                      sx={{
                        padding: 3,
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: theme.palette.grey[50],
                        maxHeight: 600, // Define a altura máxima para manter a consistência
                        overflowY: "auto", // Permite rolagem se necessário
                      }}
                    >
                      <Typography
                        variant="h6"
                        color="textSecondary"
                        sx={{ textAlign: "center", fontStyle: "italic" }}
                      >
                        Por favor, selecione uma opção para continuar.
                      </Typography>
                    </Paper>
                  )}

                  {/* Exibição de Erro se Nenhuma Opção for Selecionada */}
                  {error && (
                    <Box mt={2}>
                      <Alert severity="error">
                        Por favor, selecione uma opção antes de continuar.
                      </Alert>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Container>
        </section>
      </div>

      <AppFooter />
      <AppSetting />
    </>
  );
}

