import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Login from './components/basic/login/Login';
import Home from './components/basic/home/Home';
import EditManageSsj from './components/custom/manage-ssj/EditManageSsj';
import ViewManageSsj from './components/custom/manage-ssj/ViewManageSsj';
import DashboardManageSsj from './components/custom/manage-ssj/dashboard/DashboardManageSsj';
import CreateProject from './components/custom/holding/project/CreateProject';
import EditProject from './components/custom/holding/project/EditProject';
import ViewProjects from './components/custom/holding/project/ViewProjects';
import CreateLegal from './components/custom/holding/partners/create-partner/CreateLegal';
import CreatePhysical from './components/custom/holding/partners/create-partner/CreatePhysical';
import EditPartner from './components/custom/holding/partners/EditPartner';
import ViewPartners from './components/custom/holding/partners/ViewPartners';
import CreateAsset from './components/custom/holding/assets/CreateAsset';
import EditAsset from './components/custom/holding/assets/EditAsset';
import ViewAssets from './components/custom/holding/assets/ViewAssets';
import HomeAdm from './components/custom/admin/adm-home/HomeAdm';
import ViewClients from './components/custom/admin/clients/ViewClients';
import CreateClient from './components/custom/admin/clients/CreateClient';
import ViewAdminUsers from './components/custom/admin/admin-users/ViewAdminUsers';
import CreateAdminUser from './components/custom/admin/admin-users/CreateAdminUser';
import EditAdminUser from './components/custom/admin/admin-users/EditAdminUser';
import AdmLogin from './components/custom/admin/adm-login/AdmLogin';
import ViewUsers from './components/custom/manage-users/ViewUsers';
import CreateUser from './components/custom/manage-users/CreateUser';
import EditUser from './components/custom/manage-users/EditUser';
import EditClient from './components/custom/admin/clients/EditClient';
import ViewBi from './components/custom/bi/ViewBi';
import CreateSsj from './components/custom/ssj/create-request/CreateSsj';
import CreateSsjAN from './components/custom/ssj/create-request/CreateSsjAN';
import ViewRequesters from './components/custom/ssj/add-requester/ViewRequesters';
import TableStatus from './components/custom/ssj/requests-status/TableStatus';
import FeedbackPage from './components/custom/feedback/FeedbackPage';
import FeedbackSuccess from './components/custom/feedback/FeedbackSuccess';
import FeedbackInvalid from './components/custom/feedback/FeedbackInvalid';
import NoMatch from './components/basic/no-match/NoMatch';
import RequestAnalysis from './components/custom/ssj-analysis/RequestAnalysis';
import AnalysisResult from './components/custom/ssj-analysis/AnalysisResult';
import AnalysisResultSentence from './components/custom/ssj-analysis/AnalysisResultSentence';
import RequestAnalysisSentence from './components/custom/ssj-analysis/RequestAnalysisSentence';
import AnalysisHome from './components/custom/ssj-analysis/AnalysisHome';

import AnalysisHistory from './components/custom/ssj-analysis/AnalysisHistory';
import Video from './components/custom/ssj-analysis/Video';
import SearchScreenRec from './components/custom/admin/screen-rec/SearchScreenRec';
import SendVideo from './components/custom/transcription/SendVideo';
import TranscriptionHistory from './components/custom/transcription/TranscriptionHistory';
import GeneratePrognostic from "./components/custom/ssj-analysis/GeneratePrognostic";
import PrognosticHistory from "./components/custom/ssj-analysis/PrognosticHistory";


function App() {
  return (
    <div className="wrapper">
      <BrowserRouter>
        <Routes>
          <Route path='/' exact element={<Home />}/>
          <Route path='/login' exact element={<Login />}/>◘
          <Route path='/adm' exact element={<HomeAdm />}/>
          <Route path='/adm/login' exact element={<AdmLogin />}/>
          <Route path='/manage-ssj' element={<ViewManageSsj />}/> {/* getSsj */}
          {/* <Route path='/manage-ssj/dashboard' element={<DashboardManageSsj />}/> */}
          <Route path='/edit/service/:id' element={<EditManageSsj />}/>
          <Route path='/projects' element={<ViewProjects />}/> {/* getProject */}
          <Route path='/partners' element={<ViewPartners />}/> {/* getPartner */}
          <Route path='/newPersona/physical' element={<CreatePhysical />}/>
          <Route path='/newPersona/legal' element={<CreateLegal />}/>
          <Route path='/assets/view-assets' element={<ViewAssets />}/> {/* getAssets */}
          <Route path='/assets/create-asset' element={<CreateAsset />}/>
          <Route path='/create/project' element={<CreateProject />}/>
          <Route path='/edit/project' element={<EditProject />}/>
          <Route path='/edit/asset/:id' element={<EditAsset />}/>
          <Route path='/edit/partner/:id' element={<EditPartner />}/>
          <Route path='/adm/clients' element={<ViewClients />}/>
          <Route path='/adm/create/client' element={<CreateClient />}/>
          <Route path='/adm/edit/client/:id' element={<EditClient />}/>
          <Route path='/adm/admin-users' element={<ViewAdminUsers />}/>
          <Route path='/adm/create/admin-user' element={<CreateAdminUser />}/>
          <Route path='/adm/edit/admin-user/:id' element={<EditAdminUser />}/>
	  <Route path='/adm/screen-rec' element={<SearchScreenRec />}/>

          <Route path='/manage-users' element={<ViewUsers />}/>
          <Route path='/create/user' element={<CreateUser />}/>
          <Route path='/edit/user/:id' element={<EditUser />}/>
          <Route path='/bi' element={<ViewBi />}/>
          <Route path='/ssj' element={<CreateSsj />}/>
          <Route path='/ssj-an' element={<CreateSsjAN />}/>
         {/*  <Route path='/ssj/manage-requesters' element={<ViewRequesters />}/> */}
          <Route path='/ssj/status' element={<TableStatus />}/>
          <Route path='/feedback' element={<FeedbackPage />}/>
          <Route path='/feedback/success' element={<FeedbackSuccess />}/>
          <Route path='/feedback/invalid' element={<FeedbackInvalid />}/>
	  <Route path='/ssj/analysis' element={<AnalysisHome />}/>
          <Route path='/request/analysis/contract' element={<RequestAnalysis />}/>
          <Route path='/request/analysis/sentence' element={<RequestAnalysisSentence />}/>
	  <Route path='/analysis/result/contrato/:idAnalysis' element={<AnalysisResult/>}/>
	            <Route path='/analysis/result/sentenca/:idAnalysis' element={<AnalysisResultSentence />}/>

	  <Route path='/analysis/history' element={<AnalysisHistory />}/>
	  <Route path='/video' element={<Video />}/>
	  <Route path='/transcription' element={<SendVideo />}/>
          <Route path='/transcription/history' element={<TranscriptionHistory />}/>
	  <Route path='/prognostic/history' element={<PrognosticHistory />}/>
	  <Route path="/request/analysis/prognostic" element={<GeneratePrognostic />}/>
	  <Route path="*" element={<NoMatch />}/>

        </Routes>
      </BrowserRouter>
    </div>
  );
}
 
export default App;
