// AnalysisDescription.jsx

import React from "react";
import ReactMarkdown from "react-markdown";
import {
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Box,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

export default function AnalysisDescription({ description }) {
  return (
    <ReactMarkdown
      components={{
        // Títulos de nível 1 (h1)
        h1: ({ node, children, ...props }) => (
          <Typography variant="h4" gutterBottom {...props}>
            {children}
          </Typography>
        ),
        // Títulos de nível 2 (h2)
        h2: ({ node, children, ...props }) => (
          <Typography variant="h5" gutterBottom {...props}>
            {children}
          </Typography>
        ),
        // Títulos de nível 3 (h3)
        h3: ({ node, children, ...props }) => (
          <Typography variant="h6" gutterBottom {...props}>
            {children}
          </Typography>
        ),
        // Parágrafos
        p: ({ node, children, ...props }) => (
          <Typography variant="body1" paragraph {...props}>
            {children}
          </Typography>
        ),
        // Listas não ordenadas (ul)
        ul: ({ node, children, ...props }) => (
          <List
            sx={{
              listStyleType: "disc",
              pl: 4, // indentação à esquerda
              mb: 2,
            }}
            {...props}
          >
            {children}
          </List>
        ),
        // Itens da lista (li)
        li: ({ node, children, ...props }) => (
          <ListItem
            sx={{
              display: "flex",
              alignItems: "flex-start",
              py: 0.5,
            }}
            disablePadding
            {...props}
          >
            <ListItemIcon sx={{ minWidth: 32 }}>
              <CheckCircleOutlineIcon fontSize="small" color="primary" />
            </ListItemIcon>
            <ListItemText primary={children} />
          </ListItem>
        ),
        // Texto em negrito
        strong: ({ node, children, ...props }) => (
          <Box component="strong" fontWeight="600" {...props}>
            {children}
          </Box>
        ),
        // Divider manual (caso queira usar <hr> do markdown)
        hr: ({ ...props }) => <Divider sx={{ my: 2 }} {...props} />,
      }}
    >
      {description}
    </ReactMarkdown>
  );
}

