import AppHeader from "../../basic/app-header/AppHeader";
import AppMenu from "../../basic/app-menu/AppMenu";
import AppFooter from "../../basic/app-footer/AppFooter";
import AppSetting from "../../basic/app-setting/AppSetting";
import React, { useState } from "react";
import { postAxios } from "../../../hooks/useAxios";
import PdfUploader from "./PdfUploader";

function GeneratePrognostic() {
  const [pdfFile, setPdfFile] = useState(null);
  const [title, setTitle] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  // Cria um FormData para enviar os arquivos e dados
  const form = new FormData();

  // Função para envio do formulário
  function sendForm() {
    if (isProcessing) return; // Impede cliques adicionais enquanto já está em andamento

    setIsProcessing(true);

    // Inserção no FormData
    form.append("file", pdfFile);
    form.append("titulo", title);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    postAxios(
      "https://adm.almeidaenogueira.com.br/api/generate/prognostic",
      form,
      null,
      config
    )
      .then((resp) => {
        console.log(resp);
        // Se quiser redirecionar para uma rota de resultado, use:
         //window.location = `/prognostic/history`;
	  const consultarHistorico = window.confirm(
      "Documento criado com sucesso!\n\nClique em OK para consultar o histórico ou em Cancelar para criar outra."
    );
    if (consultarHistorico) {
      // Redireciona para a página de consulta do histórico, usando o ID retornado
      window.location.href = `/prognostic/history`;
    } 
      })
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 429) {
            alert("Muitas requisições foram feitas; tente novamente mais tarde!");
          } else {
            if (error.response.data) {
              alert(error.response.data);
            } else {
              alert("Ocorreu um erro! Tente novamente mais tarde.");
            }
          }
        } else {
          alert(
            "Erro de conexão. Por favor confira sua conexão e tente novamente."
          );
        }
      })
      .finally(() => {
        setIsProcessing(false);
      });
  }

  // Handler para atualizar o arquivo PDF
  const handlePdfFileChange = (file) => {
    setPdfFile(file);
  };

  return (
    <>
    <AppHeader />
    <AppMenu />
      <div className="content-wrapper">
        <section className="content-header" style={{ marginBottom: -5 }}>
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>
                  Geração de Carta de Prognóstico
                  <span className="beta-text">(beta)</span>
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/">Início</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Gerar Carta de Prognóstico
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              <div className="card-header bg-gray center-element">
                <h3 className="card-title" style={{ fontWeight: 600 }}>
                  Envie o arquivo inicial do processo para geração da carta
                </h3>
              </div>

              <div className="card-body">
                {/* Exibe um "loading" enquanto processa */}
                {isProcessing ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "60vh",
                    }}
                  >
                    <div className="loader-container">
                      <p className="loading-text">Gerando a carta</p>
                      <div className="loader">
                        <div className="ball"></div>
                        <div className="ball"></div>
                        <div className="ball"></div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {/* UPLOAD DO DOCUMENTO */}
                    <div className="row" style={{ marginBottom: "15px" }}>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="archive" className="required">
                            <i className="fa fa-folder" style={{ marginRight: 5 }} />
                            Anexar documento:
                          </label>
                          <PdfUploader
                            name="archive"
                            setPdfFile={handlePdfFileChange}
                          />
                        </div>
                      </div>
                    </div>

                    {/* INPUT PARA O TÍTULO */}
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="title" className="required">
                            Digite o título:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            placeholder="Digite o título da carta"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {/* BOTÕES */}
                <div className="col-12 center-element" style={{ marginTop: 15 }}>
                  <a href="/ssj/analysis">
                    <button type="button" className="cancel btn btn-gray">
                      Cancelar
                    </button>
                  </a>
                  <button
                    type="submit"
                    className="enviar btn btn-gray"
                    style={{ marginLeft: 5 }}
                    onClick={sendForm}
                    disabled={isProcessing}
                  >
                    {isProcessing ? "Aguarde..." : "Analisar"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    <AppFooter />
    <AppSetting />  
    </>
  );
}

export default GeneratePrognostic;

