import { Link } from 'react-router-dom';

function ItemMenuAnalysis() {
    return(
        <>
        <li className="nav-item has-treeview menu-open">
            <Link className="nav-link">
              <i className="nav-icon fas fa-search"></i>
              <p>
                Análise de Serviços
                <i className="right fas fa-angle-left"></i>
              </p>
            </Link>
            <ul className="nav nav-treeview">
            <li className="nav-item subitem">
                <Link to="/ssj/analysis" className="nav-link">
                <i className="fa fa-share nav-icon"></i>
                <p>Requisitar análise</p>
                </Link>
            </li>
            <li className="nav-item subitem">
                <Link to="/analysis/history" className="nav-link">
                <i className="fa fa-folder-open nav-icon"></i>
                <p>Histórico de análises</p>
                </Link>
            </li>
	    <li className="nav-item subitem">
	        <Link to="/prognostic/history" className="nav-link">
	        <i className="fa fa-folder-open nav-icon"></i>
	        <p>Histórico de cartas</p>
	        </Link>
	    </li>
            </ul>
        </li>
        </>
    );
}

export default ItemMenuAnalysis;
