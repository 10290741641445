import AppHeader from "../../basic/app-header/AppHeader";
import AppMenu from "../../basic/app-menu/AppMenu";
import AppFooter from "../../basic/app-footer/AppFooter";
import AppSetting from "../../basic/app-setting/AppSetting";
import { useState, useEffect } from "react";
import { getAxios } from "../../../hooks/useAxios";

export default function PrognosticHistory() {
    const [analysis, setAnalysis] = useState(null)

    useEffect(()=> {
    
        async function fetchData() {
            try {
                const analysisResponse = await getAxios(`https://adm.almeidaenogueira.com.br/api/get/prognostic`, null, { withCredentials: true });
                const analyzedData = analysisResponse.data;
                setAnalysis(analyzedData)
        
            } catch (error) {
                console.error('Erro ao buscar análises:', error);
            }
        }
        fetchData();
    },[])

console.log(analysis);

    if(analysis === null || analysis === '' || analysis === undefined){
        return(
            <>
            <AppHeader />
            <AppMenu />
            <div className='content-wrapper'>
                <div className='card-body'>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh"
                    }}>
                        <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
            <AppFooter />
            <AppSetting />
            </>
        );
    }


    return(
        <>
       <AppHeader />
       <AppMenu />
        {/* <!-- Content Wrapper. Contains page content --> */}
        <div class="content-wrapper">
            {/* <!-- Content Header (Page header) --> */}
            <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                <div class="col-sm-6">
                    <h1></h1>
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="/">Início</a></li>
                    </ol>
                </div>
                </div>
            </div>
            {/* <!-- /.container-fluid --> */}
            </section>

            {/* <!-- Main content --> */}
            <section class="content">
            <div class="container-fluid">
                <div class="row">
                <div class="col-12">
                    <div class="card">
                    <div class="card-header">
                        <h3 class="card-title" style={{ fontWeight: 600 }}>Cartas de prognóstico</h3>
                    </div>
                    <div class="card-body">
                        <table id="example1" class="table table-bordered table-striped dataTable dtr-inline">
                        <thead>
                            <tr>
                                <th>
                                    Título
                                </th>
                                <th>Processo</th>
                                <th>Descrição</th>
                                <th className='coluna-visualizar'>Download</th>
                            </tr>
                        </thead>
                        <tbody id="tabela-corpo">
                        {
                            analysis && analysis.map(resp=> (
                                <tr>
                                    <td><a>{resp.titulo}</a></td>
                                    <td>
                                        {`${resp.numeroProcesso}`}
                                    </td>
                                    <td><a>{resp.empresa}</a></td>
                                    <td classList="action-column" className='center-element coluna-visualizar'>
                                    <a href={`https://adm.almeidaenogueira.com.br/api/download/prognostic/${resp.id}`}>
                                        <button className='btn btn-success pen-animation'><i class='fa fa-download icon-white' style={{marginRight: 10}}></i>Download</button>
                                    </a>
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                        </table>
                    </div>
                    {/* <!-- /.card-body --> */}
                    </div>
                    {/* <!-- /.card --> */}
                </div>
                {/* <!-- /.col --> */}
                </div>
                {/* <!-- /.row --> */}
            </div>
            {/* <!-- /.container-fluid --> */}
            </section>
            {/* <!-- /.content --> */}
        </div>
        {/* <!-- /.content-wrapper --> */}
        <AppFooter />
        <AppSetting />
        </>
    )
}     
